<template>
  <div class="checkbox relative">
    <input
      type="radio"
      v-model="proxyChecked"
      :value="value"
      :id="id" />
    <label :for="id" :class="{ bold: isBold }">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: 'Radio',
  model: {
    prop: "checked",
    event: "change",
  },
  props: {
    checked: {
      type: [Array, Boolean, Number],
      default: false
    },
    value: {
      default: null
    },
    label: String,
    isBold: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    proxyChecked: {
      get() {
        return this.checked;
      },
      set(val) {
        this.$emit("change", val);
      }
    }
  },
  data () {
    return {
      id: null
    }
  }, 
  mounted () {
    this.id = this._uid
  },
}
</script>

<style scoped lang="scss">
.checkbox {
  input[type="radio"] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
  }

  input[type="radio"] + label {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
  }

  input[type="radio"] + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    width: 19px;
    height: 19px;
    text-align: center;
    background: white;
    border: 1px solid #d7d7d7;
    border-radius: 100%;
  }

  input[type="radio"]:checked + label:before {
    background: white;
    border-color: #d7d7d7;
  }

  input[type="radio"]:checked + label:after {
    content: '';
    position: absolute;
    top: 6px;
    left: 4px;
    width: 11px;
    height: 11px;
    background: #283cf0;
    border-radius: 100%;
    box-shadow: inset 0px 0px 10px rgba(0,0,0,0.3);
  }

  label {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.14px;
    color: #171717;
  }
}
</style>