<template>
  <div class="address-input">
    <label>주소 <span>*</span></label>
    <div class="address-input-group flex flex-row items-center">
      <input
        v-model="postcode"
        class="flex justify-center items-center"
        type="text"
        placeholder="우편번호"
        readonly
      />
      <button
        @click="onShow"
        class="btn flex justify-center items-center"
        type="button"
      >
        주소검색
      </button>
    </div>

    <Input
      v-model="address"
      :hasLabel="false"
      placeholder="주소 입력"
      :readonly="true"
    />
    <Input
      v-model="addressDetail"
      :hasLabel="false"
      placeholder="상세주소 입력"
    />

    <modal name="postcode" :minHeight="450" :resizable="false" :adaptive="true">
      <vue-daum-postcode style="max-height: 450px; overflow: auto;" @complete="onComplete" />
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import Input from "@/components/Input.vue";

export default {
  name: "AddressInput",
  components: { Input },
  props: {
    onChange: Function,
  },
  data() {
    return {
      postcode: "",
      address: "",
      addressDetail: "",
      oldAddress: "",
      latitude: "",
      longitude: "",
    };
  },
  methods: {
    onShow: function() {
      this.$modal.show("postcode");
    },
    onComplete: async function(result) {
      this.$modal.hide("postcode");
      const { zonecode, address, autoJibunAddress } = result;
      this.postcode = zonecode;
      this.address = address;
      this.oldAddress = autoJibunAddress;

      try {
        const kakaoData = await axios({
          method: "get",
          url: "https://dapi.kakao.com/v2/local/search/address.json",
          headers: {
            Authorization: "KakaoAK a6ccc2bf513d01266f264d20a3cb24bb",
          },
          params: { query: address },
        });
        const { data } = kakaoData;
        const { documents } = data;
        if (documents.length > 0) {
          const { x, y } = documents[0];
          this.longitude = x;
          this.latitude = y;
        }
      } catch (e) {
        console.log(e);
      }

      this.onChange({
        postcode: zonecode,
        address,
        addressDetail: "",
        oldAddress: autoJibunAddress,
        latitude: this.latitude,
        longitude: this.longitude,
      });
    },
  },
  watch: {
    addressDetail: function(newValue) {
      this.onChange({
        postcode: this.postcode,
        address: this.address,
        addressDetail: newValue,
        oldAddress: this.oldAddress,
        latitude: this.latitude,
        longitude: this.longitude,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.address-input {
  label {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: -0.56px;
    text-align: left;
    color: #555;
    margin-bottom: 10px;

    span {
      color: #f20000;
    }
  }

  input {
    width: 70px;
    margin-right: 13px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: -0.56px;
    text-align: center;
    color: #333;
    padding-bottom: 8px;
    padding-top: 8px;
    border-bottom: 1px solid #d7d7d7;

    &::placeholder {
      color: #b1b1b1;
    }
  }

  button {
    width: 87px;
    height: 29px;
    border-radius: 10px;
    background-color: #283aef;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 4.35;
    letter-spacing: -0.1px;
    text-align: left;
    color: #fff;
  }

  .input-form:not(:last-child) {
    margin-bottom: 10px;
  }

  .address-input-group {
    margin-bottom: 15px;
  }
}
</style>
